<template>
  <div class="content">
    <!--[main]-->
    <div class="main">
      <PageHeader :pageName="pageName" />
      <section class="section">
        <div class="row">
          <div class="col">
            <Panel type="sheet">
              <template v-slot:headline>顧客情報詳細</template>
              <template v-slot:body>
                <table class="table">
                  <tbody>
                    <tr v-for="item in convertedLabels" :key="item.index">
                      <th>{{ item.name }}</th>
                      <td>{{ dataTable[item.key] }}</td>
                    </tr>
                  </tbody>
                </table>
              </template>
            </Panel>
          </div>
        </div>
      </section>
    </div>
    <!--[/main]-->
    <!--[footer]-->
    <PageFooter>
      <template v-slot:footer>
        <ul class="listGrid">
          <li class="listGrid-item">
            <router-link class="btn btn-white" :to="{ name: 'ShopUser' }">
              <i class="aikon aikon-arrow_left"></i>
              顧客情報一覧に戻る
            </router-link>
          </li>
        </ul>
      </template>
    </PageFooter>
    <!--[/footer]-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { formatDateAndTime, formatDate } from '@/helpers/formatData';
import { StatusConstants } from '@/constants/status';

export default {
  name: 'ShopUserDetail',
  data: function() {
    return {
      pageName: '顧客管理',
      labels: [
        { key: 'number', name: 'user ID' },
        { key: 'name', name: 'お名前' },
        { key: 'kanaName', name: 'フリガナ'},
        { key: 'birthday', name: '生年月日' },
        { key: 'gender', name: '性別'},
        { key: 'zipcode', name: '郵便番号'},
        { key: 'telephone', name: '電話番号'},
        { key: 'email', name: 'メールアドレス / ID' },
        { key: 'password', name: 'パスワード' },
        { key: 'createDate', name: '登録日時' },
      ]
    };
  },
  computed: {
    ...mapGetters({
      infor: 'auth/infor',
      customerDetail: 'customer/customerDetail',
      subdomain: 'auth/commonSubdomain',
    }),
    customerId() {
      return this.$route.params.id;
    },
    dataTable() {
      return {
        ...this.customerDetail,
        gender: StatusConstants.gender[this.customerDetail.gender],
        name: (this.customerDetail.nameSei ?? '') + ' ' + (this.customerDetail.nameMei ?? ''),
        kanaName: (this.customerDetail.kanaSei ?? '') + ' ' + (this.customerDetail.kanaMei ?? ''),
        birthday: formatDate(this.customerDetail.birthday),
        createDate: formatDateAndTime(this.customerDetail.createDate),
        password: '********',
      };
    },
    convertedLabels() {
      return this.labels.filter(item => !this.customerDetail.isCpm ? item : (item.key !== 'email' && item.key !== 'password'));
    }
  },
  async mounted() {
    const loading = this.$loading.show();
    await this.$store.dispatch('customer/getCustomerDetail', {
      subdomain: this.subdomain,
      customerId: this.customerId,
    });
    this.$loading.clear(loading);
  },
};
</script>
